.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('~assets/login/background.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .login-logo-column {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-middle-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .login-content {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      box-shadow: 0px 0px 8px var(--shadow-color);
      width: 60%;
      padding: 24px 16px;
      .login-content-description {
        font-size: 18px;
        font-family: var(--secondary-font);
        color: var(--dark-color);
        padding-bottom: 32px;
        width: 80%;
        text-align: center;
      }
      .login-form {
        width: 70%;
        .login-form-button-container {
          text-align: center;
          .login-form-button {
            margin-top: 24px;
          }
        }
      }
    }
  }
  .login-content-elements-version {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 10px;
    font-family: var(--secondary-font);
    color: var(--secondary-color-light);
  }
}
