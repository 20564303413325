@import '../../styles/common/mixinTableRows';

.home-container {
  padding: 5%;
  .home-header {
    margin-top: -40px;
    .welcome-row {
      margin-bottom: 16px;
      padding: 24px 14px;
      border-radius: 10px;
      background-color: var(--primary-color-light);
    }
  }
  .ant-tabs-tabpane {
    padding-left: 5px;
    padding-right: 5px;
  }
  @include totalize-row;
}
