.est-add-drawer {
  .ant-drawer-header {
    .ant-drawer-title {
      display: flex;
      align-items: center;
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      color: var(--primary-color-dark);
      font-size: 14px;
      .anticon {
        font-size: 24px;
        margin-right: 10px;
      }
    }
    .ant-drawer-close {
      color: var(--primary-color-dark);
      font-size: 18px;
    }
  }
  .ant-drawer-body {
    padding: 24px 0;
    .est-add-form-wrapper {
      height: 100%;
      padding: 0 10%;
      overflow: auto;
      .est-add-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        .ant-form-item {
          display: block;
        }
        .est-add-form-input {
          width: 100%;
        }
        .est-add-form-footer {
          flex: 1;
          display: flex;
          align-items: flex-end;
          .est-add-form-button {
            width: 100%;
          }
        }
      }
    }
  }
}
